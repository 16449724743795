import QuoteModel from "../models/QuoteModel";

export const getQuotes = (): Promise<Array<QuoteModel>> => {
  return fetch("/assets/quotes.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((response) => {
    return response.json() as Promise<Array<QuoteModel>>;
  });
};
