import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { getQuotes } from "../utils/quoteUtils";
import QuoteModel from "../models/QuoteModel";

const HomeContentStyle = styled.div`
  margin: 0 auto;
  text-align: center;
  vertical-align: top;
  width: 60%;
  height: 100%;
`;

const buildQuote = (currentQuote: QuoteModel) => {
  return (
    <div>
      <h4>Random Tech Quote</h4>
      <p>{currentQuote.quote.join("\n")}</p>
      <p className="nes-text is-primary">{currentQuote.person}</p>
    </div>
  );
};

export default function HomeContent(_: any) {
  const [quote, setQuote] = useState<QuoteModel | null>(null);

  useEffect(() => {
    getQuotes().then((currentQuotes) => {
      setQuote(currentQuotes[Math.floor(Math.random() * currentQuotes.length)]);
    });
  }, []);

  const renderQuote = () => {
    const currentQuote = quote;
    if (currentQuote) {
      return buildQuote(currentQuote);
    }
  };
  return (
    <HomeContentStyle>
      <h1>Welcome!</h1>
      <p>
        My name is James Rea. I am a half-{"\ud83c\udde7\ud83c\uddea"}, half-
        {"\ud83c\uddec\ud83c\udde7"} software engineer based in{" "}
        <a href="https://en.wikipedia.org/wiki/London">London</a>.
      </p>
      <br />

      <p>
        I am currently working for <a href="https://hazy.com">Hazy</a>, where I
        work alongside ML and infrastructure engineers to scale Hazy's offering.
      </p>
      <br />
      <p>Outside of work, I run, boulder and drink overpriced coffee.</p>
      <br />

      <p>
        Please refer to my{" "}
        <a href={`${process.env.PUBLIC_URL}/assets/JamesRea_CV_July_24.pdf`}>
          CV
        </a>{" "}
        for details.
      </p>
      <br />
      <br />
      <div className="nes-container is-rounded blink_me">
        <p>
          Check out{" "}
          <a href="https://roam.jfgrea27.network">roam.jfgrea27.network</a> to
          see my knowledge tree!
        </p>
      </div>

      <br />
      <br />
      <div className="nes-container is-rounded"> {renderQuote()}</div>
    </HomeContentStyle>
  );
}
