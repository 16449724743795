import styled from "@emotion/styled";
import BlogContent from "../components/BlogContent";
import HeaderBar from "../components/HeaderBar";
import HomeContent from "../components/HomeContent";
import NotFoundContent from "../components/NotFoundContent";
import Content from "../components/Content";
import { ReactElement } from "react";

const PageViewStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export interface PageViewProps {
  name: string;
}

const renderPageContent = (pageName: string): ReactElement => {
  switch (pageName) {
    case "home":
      return <HomeContent />;
    case "blog":
      return <BlogContent />;
    default:
      return <NotFoundContent />;
  }
};

export default function PageView({ name }: PageViewProps) {
  const renderedPage = renderPageContent(name);
  return (
    <PageViewStyle>
      <HeaderBar />
      <div style={{ flex: 1 }}>
        <Content content={renderedPage} />
      </div>
    </PageViewStyle>
  );
}
