import styled from "@emotion/styled";

import { ReactElement } from "react";

export interface ContentProps {
  content: ReactElement;
}

export default function Content({ content }: any) {
  return (
    <div
      className="nes-container is-rounded"
      style={{ height: "100%", alignContent: "center" }}
    >
      {content}
    </div>
  );
}
