import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import "nes.css/css/nes.min.css";
import "./App.css";
import PageView from "./views/PageView";

const WindowStyle = styled.div`
  height: 100vh;
`;

function App() {
  return (
    <WindowStyle
      className="nes-container is-rounded"
      style={{ padding: "10px" }}
    >
      <BrowserRouter>
        <Routes>
          <Route index element={<PageView name={"home"} />} />
          <Route path="blog" element={<PageView name={"blog"} />} />
          <Route path="*" element={<PageView name={"404"} />} />
        </Routes>
      </BrowserRouter>
    </WindowStyle>
  );
}
export default App;
