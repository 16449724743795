import styled from "@emotion/styled";

import HeaderItem from "./HeaderItem";
import HeaderBar from "./HeaderBar";
import { useNavigate } from "react-router-dom";
import SocialsHeader from "./SocialsHeader";

const HeaderStyle = styled.div`
  display: flex;
  flexdirection: row;
  height: 100%;
`;
export interface HeaderProps {}

export default function Header({}: HeaderProps) {
  const navigate = useNavigate();

  return (
    <HeaderStyle>
      <div style={{ flex: 1 }}>
        <HeaderItem name="Home" handleOnClick={() => navigate("/")} />
      </div>
      <div style={{ flex: 1 }}>
        <HeaderItem
          name="Blogs & Projects"
          handleOnClick={() => navigate("/blog")}
        />
      </div>
      <div style={{ flex: 1 }}>
        <SocialsHeader />
      </div>
    </HeaderStyle>
  );
}
