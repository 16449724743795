import styled from "@emotion/styled";
const BlogContentStyle = styled.div`
  text-align: center;
  vertical-align: top;
  height: 100%;
`;

export default function BlogContent(_: any) {
  return (
    <BlogContentStyle>
      <h3 className="nes-text is-error">
        This page is pretty bare. I am currently adding projects/blog posts as
        we speak!
      </h3>
      <br />
      <h4>Please find an incomplete list below 😉:</h4>
      <br />
      <br />
      <br />

      <div className="lists" style={{ paddingLeft: 0 }}>
        <ul className="nes-list is-circle">
          {" "}
          [2024] Feed-forward Neural network for classification on wine dataset.{" "}
          <a
            href={`${process.env.PUBLIC_URL}/assets/jupyter_html/WineClassifier.html`}
          >
            Link
          </a>
        </ul>
        <br />
        <br />
        <br />

        <h4>More projects/blogs to come!</h4>
      </div>
      <p></p>
    </BlogContentStyle>
  );
}
