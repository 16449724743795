import Header from "./Header";

export interface HeaderBarProps {}

export default function HeaderBar({}: HeaderBarProps) {
  return (
    <div
      className="nes-container is-rounded"
      style={{
        padding: "0px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div>
        <img
          style={{ border: "5px solid" }}
          src={`${process.env.PUBLIC_URL}/assets/images/jfgrea27-logo.jpeg`}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Header />
      </div>
    </div>
  );
}
