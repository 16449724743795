import styled from "@emotion/styled";
import "nes.css/css/nes.min.css";

const SocialsHeaderStyle = styled.div`
  margin: 0 auto;
  text-align: center;
  vertical-align: top;
  width: 100%;
  height: 100%;
  border: 2px solid #555;
  background-color: #efefefef;
`;

export default function SocialsHeader() {
  return (
    <SocialsHeaderStyle>
      <div style={{ flex: 1 }}>Socials:</div>
      <button
        style={{ flex: 1 }}
        onClick={(e) => {
          window.location.href = "mailto:jfgrea27@gmail.com";
          e.preventDefault();
        }}
      >
        <i className="nes-icon gmail is-large"></i>
      </button>
      <button
        style={{ flex: 1 }}
        onClick={() => window.open("https://github.com/jfgrea27/")}
      >
        <i className="nes-icon github is-large"></i>
      </button>
      <button
        style={{ flex: 1 }}
        onClick={() => window.open("https://www.linkedin.com/in/james-rea/")}
      >
        <i className="nes-icon linkedin is-large"></i>
      </button>
    </SocialsHeaderStyle>
  );
}
