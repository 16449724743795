import styled from "@emotion/styled";
const NotFoundContentStyle = styled.div`
  margin: 0 auto;
  text-align: center;
  vertical-align: top;
  width: 60%;
  height: 100%;
`;

export default function NotFoundContent() {
  return (
    <NotFoundContentStyle>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/404-img.jpeg`}
        width="40%"
      />
      <h2 className="nes-text is-error">Page not found - 404.</h2>
    </NotFoundContentStyle>
  );
}
