import styled from "@emotion/styled";
import "nes.css/css/nes.min.css";

const HeaderItemStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
`;

const HeaderButtonStyle = styled.button`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
`;

export interface HeaderItemProps {
  name: string;
  handleOnClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function HeaderItem({ name, handleOnClick }: HeaderItemProps) {
  return (
    <HeaderItemStyle>
      <HeaderButtonStyle onClick={(e) => handleOnClick(e)}>
        {name}
      </HeaderButtonStyle>
    </HeaderItemStyle>
  );
}
